// 创建代收
const codeObj1_1 =
{
  java:
    String.raw`
package org.example;

import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.OutputStream;
import java.math.BigInteger;
import java.net.HttpURLConnection;
import java.net.URL;
import java.nio.charset.StandardCharsets;
import java.security.MessageDigest;
import java.util.Map;

/**
 * 支付工具
 */
public class HBOCreatePay {

    // -----------------------------------商户参数-----------------------------------
    // todo 商户代收订单回调地址
    private static String MCH_PAY_CALLBACK_URL = "https://www.google.com/";
    // todo 商户代收订单成功前端跳转地址
    private static String MCH_PAY_SUCCESS_JUMP_URL = "https://www.google.com/";
    // todo 商户代收订单失败前端跳转地址
    private static String MCH_PAY_FAIL_JUMP_URL = "https://www.google.com/";
    // todo 商户代收订单的商品名称/订单标题
    private static String MCH_PAY_TITLE = "休闲娱乐";
    // -----------------------------------我方提供参数------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    private static String MCH_APPID = "202404120393878629";
    // todo 商户后台密钥，正式使用需要替换为正式地址
    private static String MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
    // -----------------------------------我方访问地址------------------------------------
    // todo 代收订单创建地址，正式使用需要替换为正式地址
    private static String CREATE_PAY_URL = "https://test.api.hbo88.top/pay/addOrder";
    // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）------
    // 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
    private static String CURRENCY = "INR";

    /**
     * 创建代收订单
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     * @param amount 代收金额（保留2位小数）
     */
    public static String createPay(String mchOrderNo,double amount)
    {
        String amountString = String.format("%.2f",amount);
        String signStr = MCH_APPID + mchOrderNo + amountString + MCH_PAY_CALLBACK_URL + MCH_KEY;
        String sign = md5(signStr);
        String reqData = String.format(
                "{\"appId\":\"%s\"," +
                        "\"mchOrderNo\":\"%s\"," +
                        "\"amount\":\"%s\"," +
                        "\"currency\":\"%s\"," +
                        "\"notifyUrl\":\"%s\"," +
                        "\"successJumpUrl\":\"%s\"," +
                        "\"failJumpUrl\":\"%s\"," +
                        "\"body\":\"%s\"," +
                        "\"sign\":\"%s\"}",
                MCH_APPID,
                mchOrderNo,
                amountString,
                CURRENCY,
                MCH_PAY_CALLBACK_URL,
                MCH_PAY_SUCCESS_JUMP_URL,
                MCH_PAY_FAIL_JUMP_URL,
                MCH_PAY_TITLE,
                sign);

        String res =  sendPost(CREATE_PAY_URL,reqData);

        System.out.printf("签名字符串：%s\n\n" +
                "最终签名：%s\n\n" +
                "请求url：%s\n\n" +
                "请求参数：%s\n\n" +
                "返回result：%s\n\n",
                signStr,
                sign,
                CREATE_PAY_URL,
                reqData,
                res);
        return res;
    }

    /**
     * 代收订单回调
     */
    public static String notifyPay(Map<String ,String> params)
    {
            String signStr = params.get("appId") +
                    params.get("mchOrderNo") +
                    params.get("my_order_no") +
                    params.get("amount") +
                    params.get("notifyUrl") +
                    MCH_KEY;
            String sign = md5(signStr);
            if (!sign.equals(params.get("sign")) ) {
                // 签名验证失败
                return "sign wrong!";
            } else {
                if ("1".equals(params.get("pay_state"))) {
                    // 用户已经完成支付
                    return "success";
                } else {
                    // 支付异常
                    return "failed";
                }
            }
    }

    private static String sendPost(String reqUrl,String reqBody) {
        try {
            URL url = new URL(reqUrl);
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setRequestProperty("content-type", "application/json");

            conn.setDoOutput(true);

            try (OutputStream os = conn.getOutputStream()) {
                os.write(reqBody.getBytes());
            }
            int responseCode = conn.getResponseCode();
            if (responseCode == HttpURLConnection.HTTP_OK) {
                try (BufferedReader br = new BufferedReader(new InputStreamReader(conn.getInputStream()))) {
                    StringBuilder response = new StringBuilder();
                    String line;
                    while ((line = br.readLine()) != null) {
                        response.append(line);
                    }
                    return response.toString();
                }
            }
        }catch (Exception e){
            e.printStackTrace();
        }
        return "";
    }

    private static String md5(String signStr){
        byte[] secretBytes = null;
        try {
            secretBytes = MessageDigest.getInstance("md5")
                    .digest(signStr.getBytes(StandardCharsets.UTF_8));
        }catch (Exception e){
            throw new RuntimeException(e);
        }
        String md5Code = new BigInteger(1,secretBytes).toString(16);
        for (int i = 0;i < 32-md5Code.length();i++){
            md5Code = "0"+md5Code;
        }
        return md5Code;
    }

}
`,
  go:
    String.raw`
package create_pay

import (
	"bytes"
	"crypto/md5"
	"encoding/hex"
	"encoding/json"
	"fmt"
	"io"
	"net/http"
	"strings"
)

const (
	// -----------------------------------商户参数-----------------------------------
	// todo 商户代收订单回调地址
	mch_pay_callback_url = "https://www.google.com/"
	// todo 商户代收订单成功前端跳转地址
	mch_pay_success_jump_url = "https://www.google.com/"
	// todo 商户代收订单失败前端跳转地址
	mch_pay_fail_jump_url = "https://www.google.com/"
	// todo 商户代收订单的商品名称/订单标题
	mch_pay_title = "休闲娱乐"
	// -----------------------------------我方提供参数----------------------------------
	// todo 商户后台APPID，正式使用需要替换为正式地址
	mch_appid = "202404120393878629"
	// todo 商户后台密钥，正式使用需要替换为正式地址
	mch_key = "41e4c21d2ccef2e7cfbbdad5acec3bf9"
	// -----------------------------------我方访问地址----------------------------------
	// todo 代收订单创建地址，正式使用需要替换为正式地址
	create_pay_url = "https://test.api.hbo88.top/pay/addOrder"
	// -----------------------------------固定参数（根据接入数据不同可能选取不同参数）----
	// 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
	currency = "INR"
)

func CreatePay(mchOrderNo string, amount float64) string {
	amountString := fmt.Sprintf("%.2f", amount)
	signStr := mch_appid + mchOrderNo + amountString + mch_pay_callback_url + mch_key
	sign := md5Encode(signStr)
	reqData := map[string]any{
		"appId":          mch_appid,
		"mchOrderNo":     mchOrderNo,
		"amount":         amountString,
		"currency":       currency,
		"notifyUrl":      mch_pay_callback_url,
		"successJumpUrl": mch_pay_success_jump_url,
		"failJumpUrl":    mch_pay_fail_jump_url,
		"body":           mch_pay_title,
		"sign":           sign,
	}
	res := sendPost(create_pay_url, reqData)
	fmt.Printf("签名字符串：%s\n\n"+
		"最终签名：%s\n\n"+
		"请求url：%s\n\n"+
		"请求参数：%s\n\n"+
		"返回result：%s\n\n",
		signStr,
		sign,
		create_pay_url,
		reqData,
		res)
	return res
}

func NotifyPay(params map[string]string) string {
	signStr := params["appId"] +
		params["mchOrderNo"] +
		params["my_order_no"] +
		params["amount"] +
		params["notifyUrl"] +
		mch_key
	sign := md5Encode(signStr)
	if !strings.EqualFold(sign, params["sign"]) {
		// 签名验证失败
		return "sign wrong!"
	} else {
		if strings.EqualFold("1", params["pay_state"]) {
			// 用户已经完成支付
			return "success"
		} else {
			// 支付异常
			return "failed"
		}
	}
}

func sendPost(reqUrl string, reqData map[string]interface{}) string {
	marshal, err := json.Marshal(reqData)
	if err != nil {
		panic(err)
	}
	request, err := http.NewRequest("POST", reqUrl, bytes.NewBuffer(marshal))
	if err != nil {
		panic(err)
	}
	request.Header.Set("Content-Type", "application/json; charset=UTF-8")
	client := &http.Client{}
	response, err := client.Do(request)
	if err != nil {
		panic(err)
	}
	defer response.Body.Close()
	body, _ := io.ReadAll(response.Body)
	return string(body)
}

func md5Encode(signStr string) string {
	h := md5.New()
	_, err := io.WriteString(h, signStr)
	if err != nil {
		panic(err)
	}
	sum := h.Sum(nil)
	return hex.EncodeToString(sum[:])
}
`,
  php:
    String.raw`
<?php

namespace app\api\controller;

use app\common\controller\Api;

/**
 * 首页接口
 */
class HBOCreatePay extends Api
{
    protected $noNeedLogin = ['createPay','notifyPay'];
    protected $noNeedRight = ['*'];

    // -----------------------------------商户参数-----------------------------------
    // todo 商户代收订单回调地址
    const MCH_PAY_CALLBACK_URL = 'https://www.google.com/';
    // todo 商户代收订单成功前端跳转地址
    const MCH_PAY_SUCCESS_JUMP_URL = 'https://www.google.com/';
    // todo 商户代收订单失败前端跳转地址
    const MCH_PAY_FAIL_JUMP_URL = 'https://www.google.com/';
    // todo 商户代收订单的商品名称/订单标题
    const MCH_PAY_TITLE = '休闲娱乐';
    // -----------------------------------我方提供参数----------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    const MCH_APPID = '202404120393878629';
    // todo 商户后台密钥，正式使用需要替换为正式地址
    const MCH_KEY = '41e4c21d2ccef2e7cfbbdad5acec3bf9';
    // -----------------------------------我方访问地址----------------------------------
    // todo 代收订单创建地址，正式使用需要替换为正式地址
    const CREATE_PAY_URL = 'https://test.api.hbo88.top/pay/addOrder';
    // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）----
    // 订单交易币种类型---可选类型('USD','INR','USDT','CRYPTO')
    const CURRENCY = 'INR';

    /**
     * 创建代收订单
     * @param $mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     * @param $amount 代收金额（保留2位小数）
     */
    public function createPay($mchOrderNo,$amount)
    {
        $reqData = array(
            'appId' => self::MCH_APPID,
            'mchOrderNo' => $mchOrderNo,
            'amount' => sprintf("%.2f", $amount),
            'currency' => self::CURRENCY,
            'notifyUrl' => self::MCH_PAY_CALLBACK_URL,
            'successJumpUrl' => self::MCH_PAY_SUCCESS_JUMP_URL,
            'failJumpUrl' => self::MCH_PAY_FAIL_JUMP_URL,
            'body' => self::MCH_PAY_TITLE,
        );
        $signStr = $reqData['appId'] .
            $reqData['mchOrderNo'] .
            $reqData['amount'] .
            $reqData['notifyUrl'] .
            self::MCH_KEY;
        echo '签名字符串：'. $signStr . '';
        $sign = md5($signStr);
        echo '最终签名：'. $sign . '';
        $reqData['sign'] = $sign;
        $this -> sendPost(self::CREATE_PAY_URL,$reqData);

        $this->success('通讯完成！', $this->request->param());
    }

    /**
     * 代收订单回调
     */
    public function notifyPay($params = [])
    {
        if ($this->request->isPost()) {
            $reqData = $this->request->post(false); // post参数
            $requestBody = json_encode($reqData);
            echo '收到代收回调：' . $requestBody . '';
            $signStr = $reqData['appId'] .
                $reqData['mchOrderNo'] .
                $reqData['my_order_no'] .
                $reqData['amount'] .
                $reqData['notifyUrl'] .
                self::MCH_KEY;
            echo '签名字符串：' . $signStr . '';
            $sign = md5($signStr);
            echo '最终签名：' . $sign . '';
            if ($sign != $reqData['sign']) {
                // 签名验证失败
                $this->success('sign wrong!', $this->request->param());
            } else {
                if ($reqData['pay_state'] == '1') {
                    // 用户已经完成支付
                    $this->success('success', $this->request->param());
                } else {
                    // 支付异常
                    $this->success('failed', $this->request->param());
                }
            }
        }
    }

    protected function sendPost($reqUrl,$reqBody)
    {
        $curl = curl_init();
        curl_setopt($curl, CURLOPT_URL, $reqUrl);
        curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, false);
        curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, false);
        $data = json_encode($reqBody);
        curl_setopt($curl, CURLOPT_POST, 1);
        curl_setopt($curl, CURLOPT_POSTFIELDS, $data);
        curl_setopt($curl, CURLOPT_HEADER, 0);
        curl_setopt($curl, CURLOPT_HTTPHEADER,array(
            'Content-Type: application/json; charset=utf-8',
            'Content-Length:' . strlen($data),
            'Cache-Control: no-cache',
            'Pragma: no-cache'
        ));
        curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
        $result = curl_exec($curl);
        curl_close($curl);
        echo '请求url：' . $reqUrl . '';
        echo '请求参数：' . json_encode($reqBody) . '';
        echo  '返回result：' . $result . '';
        return $result;
    }
}
`,
  c:
    String.raw`
using System.Net;
using System.Security.Cryptography;
using System.Text;
using System.Text.Json.Nodes;

namespace HBODemo
{

    public class HboCreatePay
    {
        // -----------------------------------商户参数-----------------------------------
        // todo 商户代收订单回调地址
        public static string MCH_PAY_CALLBACK_URL = "https://www.google.com/";
        // todo 商户代收订单成功前端跳转地址
        public static string MCH_PAY_SUCCESS_JUMP_URL = "https://www.google.com/";
        // todo 商户代收订单失败前端跳转地址
        public static string MCH_PAY_FAIL_JUMP_URL = "https://www.google.com/";
        // todo 商户代收订单的商品名称/订单标题
        public static string MCH_PAY_TITLE = "休闲娱乐";
        // -----------------------------------我方提供参数--------------------------------------
        // todo 商户后台APPID，正式使用需要替换为正式地址
        public static string MCH_APPID = "202404120393878629";
        // todo 商户后台密钥，正式使用需要替换为正式地址
        public static string MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
        // -----------------------------------我方访问地址--------------------------------------
        // todo 代收订单创建地址，正式使用需要替换为正式地址
        public static string CREATE_PAY_URL = "https://test.api.hbo88.top/pay/addOrder";
        // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）---------
        // 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
        public static string CURRENCY = "INR";

        /**
         * 创建代收订单
         * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
         * @param amount 代收金额（保留2位小数）
         */
        public static string createPay(string mchOrderNo, double amount)
        {
            string amountString = amount.ToString("0.00");
            string signStr = MCH_APPID + mchOrderNo + amountString + MCH_PAY_CALLBACK_URL + MCH_KEY;
            string sign = md5Encode(signStr);

            JsonObject jsonObj = new JsonObject();
            jsonObj["appId"] = MCH_APPID;
            jsonObj["mchOrderNo"] = mchOrderNo;
            jsonObj["amount"] = amountString;
            jsonObj["currency"] = CURRENCY;
            jsonObj["notifyUrl"] = MCH_PAY_CALLBACK_URL;
            jsonObj["successJumpUrl"] = MCH_PAY_SUCCESS_JUMP_URL;
            jsonObj["failJumpUrl"] = MCH_PAY_FAIL_JUMP_URL;
            jsonObj["body"] = MCH_PAY_TITLE;
            jsonObj["sign"] = sign;

            string res = sendPost(CREATE_PAY_URL, jsonObj.ToString());
            Console.WriteLine($"签名字符串：{signStr}\n\n最终签名：{sign}\n\n请求url：{CREATE_PAY_URL}\n\n请求参数：{jsonObj.ToString()}\n\n返回result：{res}");
            return res;
        }

        /**
        * 代收订单回调
        */
        public static string notifyPay(Dictionary<string, string> pars)
        {
            string signStr = pars["appId"] +
                        pars["mchOrderNo"] +
                        pars["my_order_no"] +
                        pars["amount"] +
                        pars["notifyUrl"] +
                        MCH_KEY;
            string sign = md5Encode(signStr);
            if (!sign.Equals(pars["sign"]))
            {
                // 签名验证失败
                return "sign wrong!";
            }
            else
            {
                if ("1".Equals(pars["pay_state"]))
                {
                    // 用户已经完成支付
                    return "success";
                }
                else
                {
                    // 支付异常
                    return "failed";
                }
            }
        }

        public static string sendPost(string reqUrl, string reqBody)
        {
            //创建一个HTTP请求
            HttpWebRequest request = (HttpWebRequest)WebRequest.Create(reqUrl);
            //Post请求方式
            request.Method = "POST";
            //内容类型
            request.ContentType = "application/json";

            byte[] payload;
            //将Json字符串转化为字节
            payload = System.Text.Encoding.UTF8.GetBytes(reqBody);
            //设置请求的ContentLength
            request.ContentLength = payload.Length;
            //发送请求，获得请求流

            Stream writer;
            try
            {
                writer = request.GetRequestStream();//获取用于写入请求数据的Stream对象
            }
            catch (Exception)
            {
                writer = null;
                Console.Write("连接服务器失败!");
            }
            //将请求参数写入流
            writer.Write(payload, 0, payload.Length);
            writer.Close();//关闭请求流
                           // String strValue = "";//strValue为http响应所返回的字符流
            HttpWebResponse response;
            try
            {
                //获得响应流
                response = (HttpWebResponse)request.GetResponse();
            }
            catch (WebException ex)
            {
                response = ex.Response as HttpWebResponse;
            }
            Stream s = response.GetResponseStream();
            StreamReader sRead = new StreamReader(s);
            string postContent = sRead.ReadToEnd();
            sRead.Close();
            return postContent;//返回Json数据
        }

        public static string md5Encode(string signStr)
        {
            string pwd = "";
            MD5 md5 = MD5.Create(); //实例化一个md5对像
                                    // 加密后是一个字节类型的数组，这里要注意编码UTF8/Unicode等的选择　
            byte[] s = md5.ComputeHash(Encoding.UTF8.GetBytes(signStr));
            // 通过使用循环，将字节类型的数组转换为字符串，此字符串是常规字符格式化所得
            for (int i = 0; i < s.Length; i++)
            {
                // 将得到的字符串使用十六进制类型格式。格式后的字符是小写的字母，如果使用大写（X）则格式后的字符是大写字符
                pwd = pwd + s[i].ToString("x2");
            }
            return pwd;
        }

    }
}
`,
  node:
    String.raw`
// 使用代码需要执行-------【npm install axios】
const axios = require('axios');

const crypto = require('crypto');

// -----------------------------------商户参数-----------------------------------
// todo 商户代收订单回调地址
const MCH_PAY_CALLBACK_URL = "https://www.google.com/";
// todo 商户代收订单成功前端跳转地址
const MCH_PAY_SUCCESS_JUMP_URL = "https://www.google.com/";
// todo 商户代收订单失败前端跳转地址
const MCH_PAY_FAIL_JUMP_URL = "https://www.google.com/";
// todo 商户代收订单的商品名称/订单标题
const MCH_PAY_TITLE = "休闲娱乐";
// -----------------------------------我方提供参数--------------------------------------
// todo 商户后台APPID，正式使用需要替换为正式地址
const MCH_APPID = "202404120393878629";
// todo 商户后台密钥，正式使用需要替换为正式地址
const MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
// -----------------------------------我方访问地址--------------------------------------
// todo 代收订单创建地址，正式使用需要替换为正式地址
const CREATE_PAY_URL = "https://test.api.hbo88.top/pay/addOrder";
// -----------------------------------固定参数（根据接入数据不同可能选取不同参数）------------------------
// 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
const CURRENCY = "INR";

// ------------------------------------------------代收方法--------------------------------------------
async function createPay(mchOrderNo,amount){
    let reqData = {
        appId : MCH_APPID,
        mchOrderNo : mchOrderNo,
        amount : amount.toFixed(2),
        currency : CURRENCY,
        notifyUrl : MCH_PAY_CALLBACK_URL,
        successJumpUrl : MCH_PAY_SUCCESS_JUMP_URL,
        failJumpUrl : MCH_PAY_FAIL_JUMP_URL,
        body : MCH_PAY_TITLE,
    };

    let signStr = reqData.appId + reqData.mchOrderNo + reqData.amount + reqData.notifyUrl + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    reqData.sign = sign;

    let res = await sendPost(CREATE_PAY_URL,reqData);
    console.info("请求url：" + CREATE_PAY_URL);
    console.info();
    console.info("请求参数：" + JSON.stringify(reqData));
    console.info();
    console.info("返回result：" + JSON.stringify(res));
    console.info();

    return res;
}

function notifyPay(params) {
    let signStr = params.appId + params.mchOrderNo + params.my_order_no + params.amount + params.notifyUrl + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    if (sign != params.sign) {
        return "sign wrong!";
    } else {
        if (params.pay_state == '1') {
            return "success";
        } else {
            return "failed";
        }
    }
}

// ------------------------------------------------工具方法-------------------------------------------------

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function sendPost(reqUrl,reqBody){
    let back = '';
    const config = {
        method: 'post',
        url: reqUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: reqBody
    };
    axios(config)
    .then(response => {
        back = response.data;
    })
    .catch(error => {
        console.error(error); // 处理错误情况
    });
    let waitTime = 0;
    while(back == '' && waitTime < 3000){
        await sleep(200); // 休眠2秒
        waitTime += 200;
    }
    return back;
}

function md5Encode(signStr){
    let md5 = crypto.createHash('md5');
    let res = md5.update(signStr).digest('hex');
    return res;
}
`
}

const codeObj1_2 =
{
  java:
    String.raw`
package org.example;

import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.OutputStream;
import java.math.BigInteger;
import java.net.HttpURLConnection;
import java.net.URL;
import java.nio.charset.StandardCharsets;
import java.security.MessageDigest;
import java.util.Map;

/**
 * 支付工具
 */
public class HBOQueryPay {

    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    private static String MCH_APPID = "202404120393878629";
    // todo 商户后台密钥，正式使用需要替换为正式地址
    private static String MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代收订单查询地址，正式使用需要替换为正式地址
    private static String QUERY_PAY_ORDER_URL = "https://test.api.hbo88.top/pay/orderQuery";

    /**
     * 查询代收订单
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     */
    public static String queryPayOrder(String mchOrderNo)
    {
        String signStr = MCH_APPID +mchOrderNo + MCH_KEY;
        String sign = md5(signStr);
        String reqData = String.format("{\"appId\":\"%s\"," +
                        "\"mchOrderNo\":\"%s\"," +
                        "\"sign\":\"%s\"}",
                MCH_APPID,
                mchOrderNo,
                sign);
        String res = sendPost(QUERY_PAY_ORDER_URL,reqData);

        System.out.printf("签名字符串：%s\n\n" +
                        "最终签名：%s\n\n" +
                        "请求url：%s\n\n" +
                        "请求参数：%s\n\n" +
                        "返回result：%s\n\n",
                signStr,
                sign,
                QUERY_PAY_ORDER_URL,
                reqData,
                res);
        return res;
    }

    private static String sendPost(String reqUrl,String reqBody) {
        try {
            URL url = new URL(reqUrl);
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setRequestProperty("content-type", "application/json");

            conn.setDoOutput(true);

            try (OutputStream os = conn.getOutputStream()) {
                os.write(reqBody.getBytes());
            }
            int responseCode = conn.getResponseCode();
            if (responseCode == HttpURLConnection.HTTP_OK) {
                try (BufferedReader br = new BufferedReader(new InputStreamReader(conn.getInputStream()))) {
                    StringBuilder response = new StringBuilder();
                    String line;
                    while ((line = br.readLine()) != null) {
                        response.append(line);
                    }
                    return response.toString();
                }
            }
        }catch (Exception e){
            e.printStackTrace();
        }
        return "";
    }

    private static String md5(String signStr){
        byte[] secretBytes = null;
        try {
            secretBytes = MessageDigest.getInstance("md5")
                    .digest(signStr.getBytes(StandardCharsets.UTF_8));
        }catch (Exception e){
            throw new RuntimeException(e);
        }
        String md5Code = new BigInteger(1,secretBytes).toString(16);
        for (int i = 0;i < 32-md5Code.length();i++){
            md5Code = "0"+md5Code;
        }
        return md5Code;
    }

}
`,
  go:
    String.raw`
package query_pay

import (
	"bytes"
	"crypto/md5"
	"encoding/hex"
	"encoding/json"
	"fmt"
	"io"
	"net/http"
)

const (
	// -----------------------------------我方提供参数--------------------------------------
	// todo 商户后台APPID，正式使用需要替换为正式地址
	mch_appid = "202404120393878629"
	// todo 商户后台密钥，正式使用需要替换为正式地址
	mch_key = "41e4c21d2ccef2e7cfbbdad5acec3bf9"
	// -----------------------------------我方访问地址--------------------------------------
	// todo 代收订单查询地址，正式使用需要替换为正式地址
	query_pay_order_url = "https://test.api.hbo88.top/pay/orderQuery"
)

func QueryPayOrder(mchOrderNo string) string {
	signStr := mch_appid + mchOrderNo + mch_key
	sign := md5Encode(signStr)
	reqData := map[string]any{
		"appId":      mch_appid,
		"mchOrderNo": mchOrderNo,
		"sign":       sign,
	}
	res := sendPost(query_pay_order_url, reqData)
	fmt.Printf("签名字符串：%s\n\n"+
		"最终签名：%s\n\n"+
		"请求url：%s\n\n"+
		"请求参数：%s\n\n"+
		"返回result：%s\n\n",
		signStr,
		sign,
		query_pay_order_url,
		reqData,
		res)
	return res
}

func sendPost(reqUrl string, reqData map[string]interface{}) string {
	marshal, err := json.Marshal(reqData)
	if err != nil {
		panic(err)
	}
	request, err := http.NewRequest("POST", reqUrl, bytes.NewBuffer(marshal))
	if err != nil {
		panic(err)
	}
	request.Header.Set("Content-Type", "application/json; charset=UTF-8")
	client := &http.Client{}
	response, err := client.Do(request)
	if err != nil {
		panic(err)
	}
	defer response.Body.Close()
	body, _ := io.ReadAll(response.Body)
	return string(body)
}

func md5Encode(signStr string) string {
	h := md5.New()
	_, err := io.WriteString(h, signStr)
	if err != nil {
		panic(err)
	}
	sum := h.Sum(nil)
	return hex.EncodeToString(sum[:])
}
`,
  php:
    String.raw`
<?php

namespace app\api\controller;

use app\common\controller\Api;

/**
 * 首页接口
 */
class HBOQueryPay extends Api
{
    protected $noNeedLogin = ['queryPayOrder'];
    protected $noNeedRight = ['*'];

    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    const MCH_APPID = '202404120393878629';
    // todo 商户后台密钥，正式使用需要替换为正式地址
    const MCH_KEY = '41e4c21d2ccef2e7cfbbdad5acec3bf9';
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代收订单查询地址，正式使用需要替换为正式地址
    const QUERY_PAY_ORDER_URL = 'https://test.api.hbo88.top/pay/orderQuery';

    /**
     * 查询代收订单
     * @param $mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     */
    public function queryPayOrder($mchOrderNo)
    {
        $reqData = array(
            'appId' => self::MCH_APPID,
            'mchOrderNo' => $mchOrderNo,
        );
        $signStr = $reqData['appId'] .
            $reqData['mchOrderNo'] .
            self::MCH_KEY;
        echo '签名字符串：'. $signStr . '';
        $sign = md5($signStr);
        echo '最终签名：'. $sign . '';
        $reqData['sign'] = $sign;
        $this -> sendPost(self::QUERY_PAY_ORDER_URL,$reqData);

        $this->success('通讯完成！', $this->request->param());
    }

    protected function sendPost($reqUrl,$reqBody)
    {
        $curl = curl_init();
        curl_setopt($curl, CURLOPT_URL, $reqUrl);
        curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, false);
        curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, false);
        $data = json_encode($reqBody);
        curl_setopt($curl, CURLOPT_POST, 1);
        curl_setopt($curl, CURLOPT_POSTFIELDS, $data);
        curl_setopt($curl, CURLOPT_HEADER, 0);
        curl_setopt($curl, CURLOPT_HTTPHEADER,array(
            'Content-Type: application/json; charset=utf-8',
            'Content-Length:' . strlen($data),
            'Cache-Control: no-cache',
            'Pragma: no-cache'
        ));
        curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
        $result = curl_exec($curl);
        curl_close($curl);
        echo '请求url：' . $reqUrl . '';
        echo '请求参数：' . json_encode($reqBody) . '';
        echo  '返回result：' . $result . '';
        return $result;
    }
}
`,
  c:
    String.raw`
using System.Net;
using System.Security.Cryptography;
using System.Text;
using System.Text.Json.Nodes;

namespace HBODemo
{

    public class HboQueryPay
    {
        // -----------------------------------我方提供参数--------------------------------------
        // todo 商户后台APPID，正式使用需要替换为正式地址
        public static string MCH_APPID = "202404120393878629";
        // todo 商户后台密钥，正式使用需要替换为正式地址
        public static string MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
        // -----------------------------------我方访问地址--------------------------------------
        // todo 代收订单查询地址，正式使用需要替换为正式地址
        public static string QUERY_PAY_ORDER_URL = "https://test.api.hbo88.top/pay/orderQuery";


        /**
 * 查询代收订单
 * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
 */
        public static string queryPayOrder(string mchOrderNo)
        {
            string signStr = MCH_APPID + mchOrderNo + MCH_KEY;
            string sign = md5Encode(signStr);

            JsonObject jsonObj = new JsonObject();
            jsonObj["appId"] = MCH_APPID;
            jsonObj["mchOrderNo"] = mchOrderNo;
            jsonObj["sign"] = sign;

            string res = sendPost(QUERY_PAY_ORDER_URL, jsonObj.ToString());

            Console.WriteLine($"签名字符串：{signStr}\n\n最终签名：{sign}\n\n请求url：{QUERY_PAY_ORDER_URL}\n\n请求参数：{jsonObj.ToString()}\n\n返回result：{res}");
            return res;
        }

        public static string sendPost(string reqUrl, string reqBody)
        {
            //创建一个HTTP请求
            HttpWebRequest request = (HttpWebRequest)WebRequest.Create(reqUrl);
            //Post请求方式
            request.Method = "POST";
            //内容类型
            request.ContentType = "application/json";

            byte[] payload;
            //将Json字符串转化为字节
            payload = System.Text.Encoding.UTF8.GetBytes(reqBody);
            //设置请求的ContentLength
            request.ContentLength = payload.Length;
            //发送请求，获得请求流

            Stream writer;
            try
            {
                writer = request.GetRequestStream();//获取用于写入请求数据的Stream对象
            }
            catch (Exception)
            {
                writer = null;
                Console.Write("连接服务器失败!");
            }
            //将请求参数写入流
            writer.Write(payload, 0, payload.Length);
            writer.Close();//关闭请求流
                           // String strValue = "";//strValue为http响应所返回的字符流
            HttpWebResponse response;
            try
            {
                //获得响应流
                response = (HttpWebResponse)request.GetResponse();
            }
            catch (WebException ex)
            {
                response = ex.Response as HttpWebResponse;
            }
            Stream s = response.GetResponseStream();
            StreamReader sRead = new StreamReader(s);
            string postContent = sRead.ReadToEnd();
            sRead.Close();
            return postContent;//返回Json数据
        }

        public static string md5Encode(string signStr)
        {
            string pwd = "";
            MD5 md5 = MD5.Create(); //实例化一个md5对像
                                    // 加密后是一个字节类型的数组，这里要注意编码UTF8/Unicode等的选择　
            byte[] s = md5.ComputeHash(Encoding.UTF8.GetBytes(signStr));
            // 通过使用循环，将字节类型的数组转换为字符串，此字符串是常规字符格式化所得
            for (int i = 0; i < s.Length; i++)
            {
                // 将得到的字符串使用十六进制类型格式。格式后的字符是小写的字母，如果使用大写（X）则格式后的字符是大写字符
                pwd = pwd + s[i].ToString("x2");
            }
            return pwd;
        }

    }
}
`,
  node:
    String.raw`
// 使用代码需要执行-------【npm install axios】
const axios = require('axios');

const crypto = require('crypto');

// -----------------------------------我方提供参数--------------------------------------
// todo 商户后台APPID，正式使用需要替换为正式地址
const MCH_APPID = "202404120393878629";
// todo 商户后台密钥，正式使用需要替换为正式地址
const MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
// -----------------------------------我方访问地址--------------------------------------
// todo 代收订单查询地址，正式使用需要替换为正式地址
const QUERY_PAY_ORDER_URL = "https://test.api.hbo88.top/pay/orderQuery";

// ------------------------------------------------代收方法-----------------------------
async function queryPayOrder(mchOrderNo){
    let reqData = {
        appId : MCH_APPID,
        mchOrderNo : mchOrderNo,
    };

    let signStr = reqData.appId + reqData.mchOrderNo + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    reqData.sign = sign;

    let res = await sendPost(QUERY_PAY_ORDER_URL,reqData);
    console.info("请求url：" + QUERY_PAY_ORDER_URL);
    console.info();
    console.info("请求参数：" + JSON.stringify(reqData));
    console.info();
    console.info("返回result：" + JSON.stringify(res));
    console.info();

    return res;
}

// ------------------------------------------------工具方法--------------------------------

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function sendPost(reqUrl,reqBody){
    let back = '';
    const config = {
        method: 'post',
        url: reqUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: reqBody
    };
    axios(config)
    .then(response => {
        back = response.data;
    })
    .catch(error => {
        console.error(error); // 处理错误情况
    });
    let waitTime = 0;
    while(back == '' && waitTime < 3000){
        await sleep(200); // 休眠2秒
        waitTime += 200;
    }
    return back;
}

function md5Encode(signStr){
    let md5 = crypto.createHash('md5');
    let res = md5.update(signStr).digest('hex');
    return res;
}
`
}

const codeObj2_1 =
{
  java:
    String.raw`
package org.example;

import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.OutputStream;
import java.math.BigInteger;
import java.net.HttpURLConnection;
import java.net.URL;
import java.nio.charset.StandardCharsets;
import java.security.MessageDigest;
import java.util.Map;

/**
 * 支付工具
 */
public class HBOCreateTransfer {

    // -----------------------------------商户参数-----------------------------------
    // todo 商户代付订单回调地址
    private static String MCH_TRANSFER_CALLBACK_URL = "https://www.google.com/";
    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    private static String MCH_APPID = "202404120393878629";
    // todo 商户后台密钥，正式使用需要替换为正式地址
    private static String MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
    // todo 商户代付通道ID，正式使用需要替换为正式地址
    private static int MCH_CHANNEL_ID = 100011;
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代付订单创建地址，正式使用需要替换为正式地址
    private static String CREATE_TRANSFER_URL="https://test.api.hbo88.top/transfer/addOrder";
    // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）--------------------------------------
    // 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
    private static String CURRENCY = "INR";

    /**
     * 创建代付订单(银行卡)
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     * @param amount 代收金额（保留2位小数）
     * @param ifsc 各个国家银行代号（印度卢比INR 需要填写IFSC代碼，ifsc是印度银行卡代号,会员自己填写，不需要再去获取银行列表，bankCode 对应 ifsc; ）
     */
    public static String createTransfer(String mchOrderNo,double amount,String ifsc,String accountName,String accountNo) {
        String amountString = String.format("%.2f",amount);
        String signStr = MCH_APPID +mchOrderNo + amountString + CURRENCY +MCH_TRANSFER_CALLBACK_URL+ MCH_KEY;
        String sign = md5(signStr);
        String reqData = String.format("{\"appId\":\"%s\"," +
                        "\"mchOrderNo\":\"%s\"," +
                        "\"amount\":\"%s\"," +
                        "\"passageId\":\"%d\"," +
                        "\"currency\":\"%s\"," +
                        "\"notifyUrl\":\"%s\"," +
                        "\"bankCode\":\"%s\"," +
                        "\"accountName\":\"%s\"," +
                        "\"accountNo\":\"%s\"," +
                        "\"sign\":\"%s\"}",
                MCH_APPID,
                mchOrderNo,
                amountString,
                MCH_CHANNEL_ID,
                CURRENCY,
                MCH_TRANSFER_CALLBACK_URL,
                ifsc,
                accountName,
                accountNo,
                sign);
        String res = sendPost(CREATE_TRANSFER_URL,reqData);
        System.out.printf("签名字符串：%s\n\n" +
                        "最终签名：%s\n\n" +
                        "请求url：%s\n\n" +
                        "请求参数：%s\n\n" +
                        "返回result：%s\n\n",
                signStr,
                sign,
                CREATE_TRANSFER_URL,
                reqData,
                res);
        return res;
    }

    /**
     * 代付订单回调
     */
    public static String notifyTransfer(Map<String ,String> params) {
        String signStr = params.get("appId") +
                params.get("mchOrderNo") +
                params.get("my_order_no") +
                params.get("amount") +
                params.get("currency") +
                params.get("notifyUrl") +
                MCH_KEY;
        String sign = md5(signStr);

        if (!sign.equals(params.get("sign")) ) {
            // 签名验证失败
            return "sign wrong!";
        } else {
            if ("4".equals(params.get("transfer_state"))) {
                // 用户已经完成支付
                return "success";
            } else {
                // 支付异常
                return "failed";
            }
        }
    }

    private static String sendPost(String reqUrl,String reqBody) {
        try {
            URL url = new URL(reqUrl);
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setRequestProperty("content-type", "application/json");

            conn.setDoOutput(true);

            try (OutputStream os = conn.getOutputStream()) {
                os.write(reqBody.getBytes());
            }
            int responseCode = conn.getResponseCode();
            if (responseCode == HttpURLConnection.HTTP_OK) {
                try (BufferedReader br = new BufferedReader(new InputStreamReader(conn.getInputStream()))) {
                    StringBuilder response = new StringBuilder();
                    String line;
                    while ((line = br.readLine()) != null) {
                        response.append(line);
                    }
                    return response.toString();
                }
            }
        }catch (Exception e){
            e.printStackTrace();
        }
        return "";
    }

    private static String md5(String signStr){
        byte[] secretBytes = null;
        try {
            secretBytes = MessageDigest.getInstance("md5")
                    .digest(signStr.getBytes(StandardCharsets.UTF_8));
        }catch (Exception e){
            throw new RuntimeException(e);
        }
        String md5Code = new BigInteger(1,secretBytes).toString(16);
        for (int i = 0;i < 32-md5Code.length();i++){
            md5Code = "0"+md5Code;
        }
        return md5Code;
    }

}
`,
  go:
    String.raw`
package create_transfer

import (
	"bytes"
	"crypto/md5"
	"encoding/hex"
	"encoding/json"
	"fmt"
	"io"
	"net/http"
	"strings"
)

const (
	// -----------------------------------商户参数-----------------------------------
	// todo 商户代付订单回调地址
	mch_transfer_callback_url = "https://www.google.com/"
	// -----------------------------------我方提供参数--------------------------------------
	// todo 商户后台APPID，正式使用需要替换为正式地址
	mch_appid = "202404120393878629"
	// todo 商户后台密钥，正式使用需要替换为正式地址
	mch_key = "41e4c21d2ccef2e7cfbbdad5acec3bf9"
	// todo 商户代付通道ID，正式使用需要替换为正式地址
	mch_channel_id = 100011
	// -----------------------------------我方访问地址--------------------------------------
	// todo 代付订单创建地址，正式使用需要替换为正式地址
	create_transfer_url = "https://test.api.hbo88.top/transfer/addOrder"
	// -----------------------------------固定参数（根据接入数据不同可能选取不同参数）--------------------------------------
	// 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
	currency = "INR"
)

func CreateTransfer(mchOrderNo string, amount float64, ifsc, accountName, accountNo string) string {
	amountString := fmt.Sprintf("%.2f", amount)
	signStr := mch_appid + mchOrderNo + amountString + currency + mch_transfer_callback_url + mch_key
	sign := md5Encode(signStr)
	reqData := map[string]any{
		"appId":       mch_appid,
		"mchOrderNo":  mchOrderNo,
		"amount":      amountString,
		"passageId":   mch_channel_id,
		"currency":    currency,
		"notifyUrl":   mch_transfer_callback_url,
		"bankCode":    ifsc,
		"accountName": accountName,
		"accountNo":   accountNo,
		"sign":        sign,
	}
	res := sendPost(create_transfer_url, reqData)
	fmt.Printf("签名字符串：%s\n\n"+
		"最终签名：%s\n\n"+
		"请求url：%s\n\n"+
		"请求参数：%s\n\n"+
		"返回result：%s\n\n",
		signStr,
		sign,
		create_transfer_url,
		reqData,
		res)
	return res
}

func NotifyTransfer(params map[string]string) string {
	signStr := params["appId"] +
		params["mchOrderNo"] +
		params["my_order_no"] +
		params["amount"] +
		params["currency"] +
		params["notifyUrl"] +
		mch_key
	sign := md5Encode(signStr)
	if !strings.EqualFold(sign, params["sign"]) {
		// 签名验证失败
		return "sign wrong!"
	} else {
		if strings.EqualFold("4", params["transfer_state"]) {
			// 用户已经完成支付
			return "success"
		} else {
			// 支付异常
			return "failed"
		}
	}
}

func sendPost(reqUrl string, reqData map[string]interface{}) string {
	marshal, err := json.Marshal(reqData)
	if err != nil {
		panic(err)
	}
	request, err := http.NewRequest("POST", reqUrl, bytes.NewBuffer(marshal))
	if err != nil {
		panic(err)
	}
	request.Header.Set("Content-Type", "application/json; charset=UTF-8")
	client := &http.Client{}
	response, err := client.Do(request)
	if err != nil {
		panic(err)
	}
	defer response.Body.Close()
	body, _ := io.ReadAll(response.Body)
	return string(body)
}

func md5Encode(signStr string) string {
	h := md5.New()
	_, err := io.WriteString(h, signStr)
	if err != nil {
		panic(err)
	}
	sum := h.Sum(nil)
	return hex.EncodeToString(sum[:])
}
`,
  php:
    String.raw`
<?php

namespace app\api\controller;

use app\common\controller\Api;

/**
 * 首页接口
 */
class HBOCreateTransfer extends Api
{
    protected $noNeedLogin = ['createTransfer','notifyTransfer'];
    protected $noNeedRight = ['*'];

    // -----------------------------------商户参数-----------------------------------
    // todo 商户代付订单回调地址
    const MCH_TRANSFER_CALLBACK_URL = 'https://www.google.com/';
    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    const MCH_APPID = '202404120393878629';
    // todo 商户后台密钥，正式使用需要替换为正式地址
    const MCH_KEY = '41e4c21d2ccef2e7cfbbdad5acec3bf9';
    // todo 商户代付通道ID，正式使用需要替换为正式地址
    const MCH_CHANNEL_ID = 100011;
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代付订单创建地址，正式使用需要替换为正式地址
    const CREATE_TRANSFER_URL='https://test.api.hbo88.top/transfer/addOrder';
    // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）--------------------------------------
    // 订单交易币种类型---可选类型('USD','INR','USDT','CRYPTO')
    const CURRENCY = 'INR';

    /**
     * 创建代付订单(银行卡)
     * @param $mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     * @param $amount 代收金额（保留2位小数）
     * @param $ifsc 各个国家银行代号（印度卢比INR 需要填写IFSC代碼，ifsc是印度银行卡代号,会员自己填写，不需要再去获取银行列表，bankCode 对应 ifsc; ）
     */
    public function createTransfer($mchOrderNo,$amount,$ifsc,$accountName,$accountNo)
    {
        $reqData = array(
            'appId' => self::MCH_APPID,
            'mchOrderNo' => $mchOrderNo,
            'amount' => sprintf("%.2f", $amount),
            'passageId' => self::MCH_CHANNEL_ID,
            'currency' => self::CURRENCY,
            'notifyUrl' => self::MCH_TRANSFER_CALLBACK_URL,
            'bankCode' => $ifsc,
            'accountName' => $accountName,
            'accountNo' => $accountNo,
        );
        $signStr = $reqData['appId'] .
            $reqData['mchOrderNo'] .
            $reqData['amount'] .
            $reqData['currency'] .
            $reqData['notifyUrl'] .
            self::MCH_KEY;
        echo '签名字符串：'. $signStr . '';
        $sign = md5($signStr);
        echo '最终签名：'. $sign . '';
        $reqData['sign'] = $sign;
        $this -> sendPost(self::CREATE_TRANSFER_URL,$reqData);

        $this->success('通讯完成！', $this->request->param());
    }

    /**
     * 代付订单回调
     */
    public function notifyTransfer($params = [])
    {
        if ($this->request->isPost()) {
            $reqData = $this->request->post(false); // post参数
            $requestBody = json_encode($reqData);
            echo '收到代付回调：'. $requestBody . '';
            $signStr = $reqData['appId'] .
                $reqData['mchOrderNo'] .
                $reqData['my_order_no'] .
                $reqData['amount'] .
                $reqData['currency'] .
                $reqData['notifyUrl'] .
                self::MCH_KEY;
            echo '签名字符串：'. $signStr . '';
            $sign = md5($signStr);
            echo '最终签名：'. $sign . '';
            if ($sign != $reqData['sign']){
                // 签名验证失败
                $this->success('sign wrong!', $this->request->param());
            } else {
                if ($reqData['transfer_state'] == '4'){
                    // 用户已经完成支付
                    $this->success('success', $this->request->param());
                } else {
                    // 支付异常
                    $this->success('failed', $this->request->param());
                }
            }
        }
    }

    protected function sendPost($reqUrl,$reqBody)
    {
        $curl = curl_init();
        curl_setopt($curl, CURLOPT_URL, $reqUrl);
        curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, false);
        curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, false);
        $data = json_encode($reqBody);
        curl_setopt($curl, CURLOPT_POST, 1);
        curl_setopt($curl, CURLOPT_POSTFIELDS, $data);
        curl_setopt($curl, CURLOPT_HEADER, 0);
        curl_setopt($curl, CURLOPT_HTTPHEADER,array(
            'Content-Type: application/json; charset=utf-8',
            'Content-Length:' . strlen($data),
            'Cache-Control: no-cache',
            'Pragma: no-cache'
        ));
        curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
        $result = curl_exec($curl);
        curl_close($curl);
        echo '请求url：' . $reqUrl . '';
        echo '请求参数：' . json_encode($reqBody) . '';
        echo  '返回result：' . $result . '';
        return $result;
    }
}
`,
  c:
    String.raw`
using System.Net;
using System.Security.Cryptography;
using System.Text;
using System.Text.Json.Nodes;

namespace HBODemo
{

    public class HboCreateTransfer
    {
        // -----------------------------------商户参数-----------------------------------
        // todo 商户代付订单回调地址
        public static string MCH_TRANSFER_CALLBACK_URL = "https://www.google.com/";
        // -----------------------------------我方提供参数--------------------------------------
        // todo 商户后台APPID，正式使用需要替换为正式地址
        public static string MCH_APPID = "202404120393878629";
        // todo 商户后台密钥，正式使用需要替换为正式地址
        public static string MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
        // todo 商户代付通道ID，正式使用需要替换为正式地址
        public static int MCH_CHANNEL_ID = 100011;
        // -----------------------------------我方访问地址--------------------------------------
        // todo 代付订单创建地址，正式使用需要替换为正式地址
        public static string CREATE_TRANSFER_URL = "https://test.api.hbo88.top/transfer/addOrder";
        // -----------------------------------固定参数（根据接入数据不同可能选取不同参数）--------------------------------------
        // 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
        public static string CURRENCY = "INR";

        /**
     * 创建代付订单(银行卡)
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     * @param amount 代收金额（保留2位小数）
     * @param ifsc 各个国家银行代号（印度卢比INR 需要填写IFSC代碼，ifsc是印度银行卡代号,会员自己填写，不需要再去获取银行列表，bankCode 对应 ifsc; ）
     */
        public static string createTransfer(string mchOrderNo, double amount, string ifsc, string accountName, string accountNo)
        {
            string amountString = amount.ToString("0.00");
            string signStr = MCH_APPID + mchOrderNo + amountString + CURRENCY + MCH_TRANSFER_CALLBACK_URL + MCH_KEY;
            string sign = md5Encode(signStr);

            JsonObject jsonObj = new JsonObject();
            jsonObj["appId"] = MCH_APPID;
            jsonObj["mchOrderNo"] = mchOrderNo;
            jsonObj["amount"] = amountString;
            jsonObj["passageId"] = MCH_CHANNEL_ID;
            jsonObj["currency"] = CURRENCY;
            jsonObj["notifyUrl"] = MCH_TRANSFER_CALLBACK_URL;
            jsonObj["bankCode"] = ifsc;
            jsonObj["accountName"] = accountName;
            jsonObj["accountNo"] = accountNo;
            jsonObj["sign"] = sign;

            string res = sendPost(CREATE_TRANSFER_URL, jsonObj.ToString());

            Console.WriteLine($"签名字符串：{signStr}\n\n最终签名：{sign}\n\n请求url：{CREATE_TRANSFER_URL}\n\n请求参数：{jsonObj.ToString()}\n\n返回result：{res}");

            return res;
        }

        /**
 * 代付订单回调
 */
        public static string notifyTransfer(Dictionary<string, string> pars)
        {
            string signStr = pars["appId"] +
                    pars["mchOrderNo"] +
                    pars["my_order_no"] +
                    pars["amount"] +
                    pars["currency"] +
                    pars["notifyUrl"] +
                    MCH_KEY;
            string sign = md5Encode(signStr);

            if (!sign.Equals(pars["sign"]))
            {
                // 签名验证失败
                return "sign wrong!";
            }
            else
            {
                if ("4".Equals(pars["transfer_state"]))
                {
                    // 用户已经完成支付
                    return "success";
                }
                else
                {
                    // 支付异常
                    return "failed";
                }
            }
        }

        public static string sendPost(string reqUrl, string reqBody)
        {
            //创建一个HTTP请求
            HttpWebRequest request = (HttpWebRequest)WebRequest.Create(reqUrl);
            //Post请求方式
            request.Method = "POST";
            //内容类型
            request.ContentType = "application/json";

            byte[] payload;
            //将Json字符串转化为字节
            payload = System.Text.Encoding.UTF8.GetBytes(reqBody);
            //设置请求的ContentLength
            request.ContentLength = payload.Length;
            //发送请求，获得请求流

            Stream writer;
            try
            {
                writer = request.GetRequestStream();//获取用于写入请求数据的Stream对象
            }
            catch (Exception)
            {
                writer = null;
                Console.Write("连接服务器失败!");
            }
            //将请求参数写入流
            writer.Write(payload, 0, payload.Length);
            writer.Close();//关闭请求流
                           // String strValue = "";//strValue为http响应所返回的字符流
            HttpWebResponse response;
            try
            {
                //获得响应流
                response = (HttpWebResponse)request.GetResponse();
            }
            catch (WebException ex)
            {
                response = ex.Response as HttpWebResponse;
            }
            Stream s = response.GetResponseStream();
            StreamReader sRead = new StreamReader(s);
            string postContent = sRead.ReadToEnd();
            sRead.Close();
            return postContent;//返回Json数据
        }

        public static string md5Encode(string signStr)
        {
            string pwd = "";
            MD5 md5 = MD5.Create(); //实例化一个md5对像
                                    // 加密后是一个字节类型的数组，这里要注意编码UTF8/Unicode等的选择　
            byte[] s = md5.ComputeHash(Encoding.UTF8.GetBytes(signStr));
            // 通过使用循环，将字节类型的数组转换为字符串，此字符串是常规字符格式化所得
            for (int i = 0; i < s.Length; i++)
            {
                // 将得到的字符串使用十六进制类型格式。格式后的字符是小写的字母，如果使用大写（X）则格式后的字符是大写字符
                pwd = pwd + s[i].ToString("x2");
            }
            return pwd;
        }

    }
}
`,
  node:
    String.raw`
// 使用代码需要执行-------【npm install axios】
const axios = require('axios');

const crypto = require('crypto');

// -----------------------------------商户参数-----------------------------------
// todo 商户代付订单回调地址
const MCH_TRANSFER_CALLBACK_URL = "https://www.google.com/";
// -----------------------------------我方提供参数--------------------------------------
// todo 商户后台APPID，正式使用需要替换为正式地址
const MCH_APPID = "202404120393878629";
// todo 商户后台密钥，正式使用需要替换为正式地址
const MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
// todo 商户代付通道ID，正式使用需要替换为正式地址
const MCH_CHANNEL_ID = 100011;
// -----------------------------------我方访问地址--------------------------------------
// todo 代付订单创建地址，正式使用需要替换为正式地址
const CREATE_TRANSFER_URL="https://test.api.hbo88.top/transfer/addOrder";
// -----------------------------------固定参数（根据接入数据不同可能选取不同参数）--------------------------------------
// 订单交易币种类型---可选类型("USD","INR","USDT","CRYPTO")
const CURRENCY = "INR";

// ------------------------------------------------代付方法-------------------------------------------------
async function createTransfer(mchOrderNo,amount,ifsc,accountName,accountNo) {
    let reqData = {
        appId : MCH_APPID,
        mchOrderNo : mchOrderNo,
        amount : amount.toFixed(2),
        passageId : MCH_CHANNEL_ID,
        currency : CURRENCY,
        notifyUrl : MCH_TRANSFER_CALLBACK_URL,
        bankCode : ifsc,
        accountName : accountName,
        accountNo : accountNo,
    };

    let signStr = reqData.appId + reqData.mchOrderNo + reqData.amount + reqData.currency + reqData.notifyUrl + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    reqData.sign = sign;

    let res = await sendPost(CREATE_TRANSFER_URL,reqData);
    console.info("请求url：" + CREATE_TRANSFER_URL);
    console.info();
    console.info("请求参数：" + JSON.stringify(reqData));
    console.info();
    console.info("返回result：" + JSON.stringify(res));
    console.info();

    return res;
}

function notifyTransfer(params) {
    let signStr = params.appId + params.mchOrderNo + params.my_order_no + params.amount + params.currency + params.notifyUrl + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    if (sign != params.sign) {
        return "sign wrong!";
    } else {
        if (params.transfer_state == '4') {
            return "success";
        } else {
            return "failed";
        }
    }
}

// ------------------------------------------------工具方法-------------------------------------------------

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function sendPost(reqUrl,reqBody){
    let back = '';
    const config = {
        method: 'post',
        url: reqUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: reqBody
    };
    axios(config)
    .then(response => {
        back = response.data;
    })
    .catch(error => {
        console.error(error); // 处理错误情况
    });
    let waitTime = 0;
    while(back == '' && waitTime < 3000){
        await sleep(200); // 休眠2秒
        waitTime += 200;
    }
    return back;
}

function md5Encode(signStr){
    let md5 = crypto.createHash('md5');
    let res = md5.update(signStr).digest('hex');
    return res;
}
`
}

const codeObj2_2 =
{
  java:
    String.raw`
package org.example;

import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.OutputStream;
import java.math.BigInteger;
import java.net.HttpURLConnection;
import java.net.URL;
import java.nio.charset.StandardCharsets;
import java.security.MessageDigest;
import java.util.Map;

/**
 * 支付工具
 */
public class HBOQueryTransfer {

    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    private static String MCH_APPID = "202404120393878629";
    // todo 商户后台密钥，正式使用需要替换为正式地址
    private static String MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代付订单查询地址，正式使用需要替换为正式地址
    private static String QUERY_TRANSFER_ORDER_URL = "https://test.api.hbo88.top/transfer/orderQuery";

    /**
     * 查询代付订单
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     */
    public static String queryTransferOrder(String mchOrderNo)
    {
        String signStr = MCH_APPID +mchOrderNo + MCH_KEY;
        String sign = md5(signStr);
        String reqData = String.format("{\"appId\":\"%s\"," +
                        "\"mchOrderNo\":\"%s\"," +
                        "\"sign\":\"%s\"}",
                MCH_APPID,
                mchOrderNo,
                sign);
        String res = sendPost(QUERY_TRANSFER_ORDER_URL,reqData);

        System.out.printf("签名字符串：%s\n\n" +
                        "最终签名：%s\n\n" +
                        "请求url：%s\n\n" +
                        "请求参数：%s\n\n" +
                        "返回result：%s\n\n",
                signStr,
                sign,
                QUERY_TRANSFER_ORDER_URL,
                reqData,
                res);
        return res;
    }

    private static String sendPost(String reqUrl,String reqBody) {
        try {
            URL url = new URL(reqUrl);
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setRequestProperty("content-type", "application/json");

            conn.setDoOutput(true);

            try (OutputStream os = conn.getOutputStream()) {
                os.write(reqBody.getBytes());
            }
            int responseCode = conn.getResponseCode();
            if (responseCode == HttpURLConnection.HTTP_OK) {
                try (BufferedReader br = new BufferedReader(new InputStreamReader(conn.getInputStream()))) {
                    StringBuilder response = new StringBuilder();
                    String line;
                    while ((line = br.readLine()) != null) {
                        response.append(line);
                    }
                    return response.toString();
                }
            }
        }catch (Exception e){
            e.printStackTrace();
        }
        return "";
    }

    private static String md5(String signStr){
        byte[] secretBytes = null;
        try {
            secretBytes = MessageDigest.getInstance("md5")
                    .digest(signStr.getBytes(StandardCharsets.UTF_8));
        }catch (Exception e){
            throw new RuntimeException(e);
        }
        String md5Code = new BigInteger(1,secretBytes).toString(16);
        for (int i = 0;i < 32-md5Code.length();i++){
            md5Code = "0"+md5Code;
        }
        return md5Code;
    }

}
`,
  go:
    String.raw`
package query_transfer

import (
	"bytes"
	"crypto/md5"
	"encoding/hex"
	"encoding/json"
	"fmt"
	"io"
	"net/http"
)

const (
	// -----------------------------------我方提供参数--------------------------------------
	// todo 商户后台APPID，正式使用需要替换为正式地址
	mch_appid = "202404120393878629"
	// todo 商户后台密钥，正式使用需要替换为正式地址
	mch_key = "41e4c21d2ccef2e7cfbbdad5acec3bf9"
	// -----------------------------------我方访问地址--------------------------------------
	// todo 代付订单查询地址，正式使用需要替换为正式地址
	query_transfer_order_url = "https://test.api.hbo88.top/transfer/orderQuery"
)

func QueryTransferOrder(mchOrderNo string) string {
	signStr := mch_appid + mchOrderNo + mch_key
	sign := md5Encode(signStr)
	reqData := map[string]any{
		"appId":      mch_appid,
		"mchOrderNo": mchOrderNo,
		"sign":       sign,
	}
	res := sendPost(query_transfer_order_url, reqData)
	fmt.Printf("签名字符串：%s\n\n"+
		"最终签名：%s\n\n"+
		"请求url：%s\n\n"+
		"请求参数：%s\n\n"+
		"返回result：%s\n\n",
		signStr,
		sign,
		query_transfer_order_url,
		reqData,
		res)
	return res
}

func sendPost(reqUrl string, reqData map[string]interface{}) string {
	marshal, err := json.Marshal(reqData)
	if err != nil {
		panic(err)
	}
	request, err := http.NewRequest("POST", reqUrl, bytes.NewBuffer(marshal))
	if err != nil {
		panic(err)
	}
	request.Header.Set("Content-Type", "application/json; charset=UTF-8")
	client := &http.Client{}
	response, err := client.Do(request)
	if err != nil {
		panic(err)
	}
	defer response.Body.Close()
	body, _ := io.ReadAll(response.Body)
	return string(body)
}

func md5Encode(signStr string) string {
	h := md5.New()
	_, err := io.WriteString(h, signStr)
	if err != nil {
		panic(err)
	}
	sum := h.Sum(nil)
	return hex.EncodeToString(sum[:])
}
`,
  php:
    String.raw`
<?php

namespace app\api\controller;

use app\common\controller\Api;

/**
 * 首页接口
 */
class HBOQueryTransfer extends Api
{
    protected $noNeedLogin = ['queryTransferOrder'];
    protected $noNeedRight = ['*'];

    // -----------------------------------我方提供参数--------------------------------------
    // todo 商户后台APPID，正式使用需要替换为正式地址
    const MCH_APPID = '202404120393878629';
    // todo 商户后台密钥，正式使用需要替换为正式地址
    const MCH_KEY = '41e4c21d2ccef2e7cfbbdad5acec3bf9';
    // -----------------------------------我方访问地址--------------------------------------
    // todo 代付订单查询地址，正式使用需要替换为正式地址
    const QUERY_TRANSFER_ORDER_URL = 'https://test.api.hbo88.top/transfer/orderQuery';

    /**
     * 查询代付订单
     * @param $mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     */
    public function queryTransferOrder($mchOrderNo)
    {
        $reqData = array(
            'appId' => self::MCH_APPID,
            'mchOrderNo' => $mchOrderNo,
        );
        $signStr = $reqData['appId'] .
            $reqData['mchOrderNo'] .
            self::MCH_KEY;
        echo '签名字符串：'. $signStr . '';
        $sign = md5($signStr);
        echo '最终签名：'. $sign . '';
        $reqData['sign'] = $sign;
        $this -> sendPost(self::QUERY_TRANSFER_ORDER_URL,$reqData);

        $this->success('通讯完成！', $this->request->param());
    }

    protected function sendPost($reqUrl,$reqBody)
    {
        $curl = curl_init();
        curl_setopt($curl, CURLOPT_URL, $reqUrl);
        curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, false);
        curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, false);
        $data = json_encode($reqBody);
        curl_setopt($curl, CURLOPT_POST, 1);
        curl_setopt($curl, CURLOPT_POSTFIELDS, $data);
        curl_setopt($curl, CURLOPT_HEADER, 0);
        curl_setopt($curl, CURLOPT_HTTPHEADER,array(
            'Content-Type: application/json; charset=utf-8',
            'Content-Length:' . strlen($data),
            'Cache-Control: no-cache',
            'Pragma: no-cache'
        ));
        curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
        $result = curl_exec($curl);
        curl_close($curl);
        echo '请求url：' . $reqUrl . '';
        echo '请求参数：' . json_encode($reqBody) . '';
        echo  '返回result：' . $result . '';
        return $result;
    }
}
`,
  c:
    String.raw`
using System.Net;
using System.Security.Cryptography;
using System.Text;
using System.Text.Json.Nodes;

namespace HBODemo
{

    public class HboQueryTransfer
    {
        // -----------------------------------我方提供参数--------------------------------------
        // todo 商户后台APPID，正式使用需要替换为正式地址
        public static string MCH_APPID = "202404120393878629";
        // todo 商户后台密钥，正式使用需要替换为正式地址
        public static string MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
        // -----------------------------------我方访问地址--------------------------------------
        // todo 代付订单查询地址，正式使用需要替换为正式地址
        public static string QUERY_TRANSFER_ORDER_URL = "https://test.api.hbo88.top/transfer/orderQuery";

        /**
     * 查询代付订单
     * @param mchOrderNo 商户订单ID（商户应确保在自己的系统内唯一）
     */
        public static string queryTransferOrder(string mchOrderNo)
        {
            string signStr = MCH_APPID + mchOrderNo + MCH_KEY;
            string sign = md5Encode(signStr);
            JsonObject jsonObj = new JsonObject();
            jsonObj["appId"] = MCH_APPID;
            jsonObj["mchOrderNo"] = mchOrderNo;
            jsonObj["sign"] = sign;

            string res = sendPost(QUERY_TRANSFER_ORDER_URL, jsonObj.ToString());
            Console.WriteLine($"签名字符串：{signStr}\n\n最终签名：{sign}\n\n请求url：{QUERY_TRANSFER_ORDER_URL}\n\n请求参数：{jsonObj.ToString()}\n\n返回result：{res}");

            return res;
        }

        public static string sendPost(string reqUrl, string reqBody)
        {
            //创建一个HTTP请求
            HttpWebRequest request = (HttpWebRequest)WebRequest.Create(reqUrl);
            //Post请求方式
            request.Method = "POST";
            //内容类型
            request.ContentType = "application/json";

            byte[] payload;
            //将Json字符串转化为字节
            payload = System.Text.Encoding.UTF8.GetBytes(reqBody);
            //设置请求的ContentLength
            request.ContentLength = payload.Length;
            //发送请求，获得请求流

            Stream writer;
            try
            {
                writer = request.GetRequestStream();//获取用于写入请求数据的Stream对象
            }
            catch (Exception)
            {
                writer = null;
                Console.Write("连接服务器失败!");
            }
            //将请求参数写入流
            writer.Write(payload, 0, payload.Length);
            writer.Close();//关闭请求流
                           // String strValue = "";//strValue为http响应所返回的字符流
            HttpWebResponse response;
            try
            {
                //获得响应流
                response = (HttpWebResponse)request.GetResponse();
            }
            catch (WebException ex)
            {
                response = ex.Response as HttpWebResponse;
            }
            Stream s = response.GetResponseStream();
            StreamReader sRead = new StreamReader(s);
            string postContent = sRead.ReadToEnd();
            sRead.Close();
            return postContent;//返回Json数据
        }

        public static string md5Encode(string signStr)
        {
            string pwd = "";
            MD5 md5 = MD5.Create(); //实例化一个md5对像
                                    // 加密后是一个字节类型的数组，这里要注意编码UTF8/Unicode等的选择　
            byte[] s = md5.ComputeHash(Encoding.UTF8.GetBytes(signStr));
            // 通过使用循环，将字节类型的数组转换为字符串，此字符串是常规字符格式化所得
            for (int i = 0; i < s.Length; i++)
            {
                // 将得到的字符串使用十六进制类型格式。格式后的字符是小写的字母，如果使用大写（X）则格式后的字符是大写字符
                pwd = pwd + s[i].ToString("x2");
            }
            return pwd;
        }

    }
}
`,
  node:
    String.raw`
// 使用代码需要执行-------【npm install axios】
const axios = require('axios');

const crypto = require('crypto');

// -----------------------------------我方提供参数--------------------------------------
// todo 商户后台APPID，正式使用需要替换为正式地址
const MCH_APPID = "202404120393878629";
// todo 商户后台密钥，正式使用需要替换为正式地址
const MCH_KEY = "41e4c21d2ccef2e7cfbbdad5acec3bf9";
// -----------------------------------我方访问地址--------------------------------------
// todo 代付订单查询地址，正式使用需要替换为正式地址
const QUERY_TRANSFER_ORDER_URL = "https://test.api.hbo88.top/transfer/orderQuery";

// ------------------------------------------------代付方法------------------------------
async function queryTransferOrder(mchOrderNo) {
    let reqData = {
        appId : MCH_APPID,
        mchOrderNo : mchOrderNo,
    };

    let signStr = reqData.appId + reqData.mchOrderNo + MCH_KEY;
    console.info("签名字符串：" + signStr);
    console.info();
    let sign = md5Encode(signStr);
    console.info("最终签名：" + sign);
    console.info();

    reqData.sign = sign;

    let res = await sendPost(QUERY_TRANSFER_ORDER_URL,reqData);
    console.info("请求url：" + QUERY_TRANSFER_ORDER_URL);
    console.info();
    console.info("请求参数：" + JSON.stringify(reqData));
    console.info();
    console.info("返回result：" + JSON.stringify(res));
    console.info();

    return res;
}

// ------------------------------------------------工具方法-------------------------------

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function sendPost(reqUrl,reqBody){
    let back = '';
    const config = {
        method: 'post',
        url: reqUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: reqBody
    };
    axios(config)
    .then(response => {
        back = response.data;
    })
    .catch(error => {
        console.error(error); // 处理错误情况
    });
    let waitTime = 0;
    while(back == '' && waitTime < 3000){
        await sleep(200); // 休眠2秒
        waitTime += 200;
    }
    return back;
}

function md5Encode(signStr){
    let md5 = crypto.createHash('md5');
    let res = md5.update(signStr).digest('hex');
    return res;
}
`
}

export {
  codeObj1_1,
  codeObj1_2,
  codeObj2_1,
  codeObj2_2
}
