<template>
  <div class="app-container centerParams" id="centerParams">
    <p>
      业务描述：商户后台创建交易单后，可被动接收API服务器回调获得转账成功消息通知，也可以主动调用本接口获取订单详情。
    </p>
    <p>接口地址：https://api.hbo88.top/transfer/orderQuery</p>
    <p>数据格式：json</p>
    <p>Content-Type：application/json</p>
    <p>HTTP方法：post</p>
    <p>编码：utf-8</p>

    <el-row :gutter="20">
      <el-col :span="24" class="flag">
        <h3 @click="requiredFlag = !requiredFlag">必填项
          <i v-show="requiredFlag" class="el-icon-arrow-down"></i>
          <i v-show="!requiredFlag" class="el-icon-arrow-up"></i>
        </h3>
      </el-col>
      <el-col :span="24" v-show="requiredFlag">
        <el-form :rules="rules" ref="dataForm" label-position="left" label-width="0" :model="dataForm">
          <el-form-item label="" prop="appId">
            <span class="title"><b>*</b>appId</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户应用编号</div>
                <div>类型：字符串</div>
                <div>说明：由商户平台注册创建应用获取</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.appId" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="appSecret">
            <span class="title"><b>*</b>appSecret</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户应用密钥</div>
                <div>类型：字符串</div>
                <div>说明：在商户平台-应用管理-查看详情-查看密钥里获取</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-popover placement="top-start" title="" width="220" trigger="hover">
              <div>
                <div style="color:red">注意：此参数只参与签名{sign}计算，不要写入请求信息里</div>
              </div>
              <i class="el-icon-warning" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.appSecret" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="mchOrderNo">
            <span class="title"><b>*</b>mchOrderNo</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户订单号</div>
                <div>类型：字符串</div>
                <div>说明：商户下单时生成的订单编号</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.mchOrderNo" size="small"></el-input>
          </el-form-item>


          <el-form-item label="" prop="sign">
            <span class="title"><b>*</b>sign</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：签名</div>
                <div>类型：字符串</div>
                <div>说明：签名算法sign=MD5({appId}{mchOrderNo}{appSecret})</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.sign" size="small"></el-input>
          </el-form-item>

        </el-form>

      </el-col>
    </el-row>


    <el-row :gutter="20">
      <el-col :span="24">
        <h3>选填项</h3>
      </el-col>
      <el-col :span="24">
        <el-form label-position="left" label-width="0" :model="dataForm">
          <el-form-item label="" prop="lang">
            <span class="title">lang</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：语言</div>
                <div>类型：字符串</div>
                <div>说明：可选项为：zh-cn（简体中文），zh-hk（繁體中文），en-us（English）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.lang" size="small"></el-input>
            <!-- <el-select style="width:100%" v-model="dataForm.type" placeholder="请选择……" size="mini">
              <el-option label="zh-cn" value="zh-cn"></el-option>
              <el-option label="zh-hk" value="zh-hk"></el-option>
              <el-option label="en-us" value="en-us"></el-option>
            </el-select> -->
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col>
        <el-button size="small" @click="scrollToTop">返回顶部</el-button>
        <el-button size="small" @click="emptyData">清空</el-button>
        <el-button size="small" @click="resourceAdd" type="primary">发起调用</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import w_md5 from '@/common/w_md5'
import config from "@/api/config";
import axios from "axios";
export default {
  name: "centerParams",
  data() {
    return {
      requiredFlag: true,
      dataForm: {
        appId: "202404120393878629",
        appSecret: "41e4c21d2ccef2e7cfbbdad5acec3bf9",
        mchOrderNo: "202404120393878629",
        sign: "kljkll4fdsf1522sf2454f2ht54jhg1f7f7we97rtiu4u5jng",
        lang: "zh-cn",
      },
      rules: {
        appId: [{ required: true, message: '请输入appId', trigger: ["blur"] },],
        appSecret: [{ required: true, message: '请输入appSecret', trigger: ["blur"] },],
        mchOrderNo: [{ required: true, message: '请输入mchOrderNo', trigger: ["blur"] },],
        sign: [{ required: true, message: '请输入sign', trigger: ["blur"] },],
      },
      descripData: `
      <p>返回数据类型与addOrder接口的返回结果完全相同。</p>
      <p>商户务必根据transfer_state订单状态属性判断订单是否代付/转账成功，例如transfer_state=4的表示已转账</p>
      <br>
      <h4>转账订单状态枚举类型: transfer_state</h4>
      <p>1      //待审核</p>
      <p>2      //已审核待转款</p>
      <p>3      //已拒绝</p>
      <p>4      //已完成</p>
      `
    };
  },

  created() {
    this.init()
  },
  watch: {

  },
  methods: {
    init() {
      this.dataForm.sign = this.getSign(this.dataForm.appId + this.dataForm.mchOrderNo + this.dataForm.appSecret)
    },
    scrollToTop() {
      const myElement = document.getElementById('centerParams');
      myElement.scrollTo({
        top: 0,
        behavior: 'smooth' //可以改为'auto'，实现非平滑滚动
      });
    },
    emptyData() {
      for (let key in this.dataForm) {
        this.dataForm[key] = ''; // 将属性值置为空字符串
      }
    },
    // 提交
    resourceAdd() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.dataForm.sign = this.getSign(this.dataForm.appId + this.dataForm.mchOrderNo + this.dataForm.appSecret)
          const params = { ...this.dataForm }
          delete params.appSecret
          this.launchAction({ ...params })
          delete params.lang
          this.$eventBus.$emit('addRequestData', params)
        } else {
          this.$message.error("有必填项未填写，请检查后再调用");
        }
      });
    },
    // 添加执行
    async launchAction(params) {
      this.loadingBtn = true;
      try {
        let { data } = await axios.post(config.h5OpenBaseUrl + `transfer/orderQuery?lang=${params.lang}`, params)
        // if (data.code == 1) {
        this.$eventBus.$emit('addResponseData', data)
        this.$eventBus.$emit('addDescripData', this.descripData)
        this.init()
        this.$message.success("请求已被响应，请查看响应信息");
        // }
        this.loadingBtn = false;
      }
      catch {
        this.loadingBtn = false;
      }
    },
    getSign(data) {
      const sign = w_md5.hex_md5_32(data) //md5加密生成32位小写签名字符
      return sign
    },
  }
};
</script>

<style lang="scss" scoped>
.centerParams {
  width: 20%;
  height: calc(100vh - 100px);
  overflow-y: auto;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #555;
  line-height: 20px;

  .flag {
    cursor: pointer;

    i {
      margin-left: 10px;
    }
  }

  .title {
    padding: 0 5px 0 15px;

    b {
      color: red;
      margin-right: 2px;
    }
  }
}
</style>
