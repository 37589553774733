<template>
  <div class="app-container rightCode">
    <el-tabs v-model="type" @tab-click="handleClick1">
      <el-tab-pane label="示例代码" name="code">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick2">
          <el-tab-pane label="Java" name="Java">
            <pre>
              <code v-text="javaCode" class="language-js">
              </code>
            </pre>
          </el-tab-pane>
          <el-tab-pane label="Go" name="Go">
            <pre>
              <code v-text="goCode" class="language-js">
              </code>
            </pre>
          </el-tab-pane>
          <el-tab-pane label="PHP" name="PHP">
            <pre>
              <code v-text="phpCode" class="language-js">
              </code>
            </pre>
          </el-tab-pane>
          <el-tab-pane label="C#" name="C#">
            <pre>
              <code v-text="cCode" class="language-js">
              </code>
            </pre>
          </el-tab-pane>
          <el-tab-pane label="Node.js" name="Node.js">
            <pre>
              <code v-text="nodeCode" class="language-js">
              </code>
            </pre>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="调用结果" name="params">
        <el-card class="box-card" style="margin-bottom:20px">
          <div slot="header" class="title">
            <span>请求JSON信息</span>
          </div>
          <div class="jsonBox">
            <pre v-if="requestData">
              <code class="language-js">
{{ requestData }}
              </code>
            </pre>
            <span v-else>未发起调用，暂无请求信息</span>
          </div>
        </el-card>
        <el-card class="box-card" style="margin-bottom:20px">
          <div slot="header" class="title">
            <span>响应JSON信息</span>
          </div>
          <div class="jsonBox">
            <pre v-if="responseData">
              <code class="language-js">
{{ responseData }}
              </code>
            </pre>
            <span v-else>未发起调用，暂无响应信息</span>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="title">
            <span>响应信息说明</span>
          </div>
          <div class="jsonBox">
            <div v-html="descripData" v-if="responseData">
            </div>
            <span v-else>未发起调用，暂无响应信息说明</span>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>



  </div>
</template>

<script>
// 引入Prism.js
import Prism from 'prismjs';
// import 'prismjs/components/prism-php';
// 引入Prism.js的样式文件
import 'prismjs/themes/prism.css';
import { codeObj1_1, codeObj1_2, codeObj2_1, codeObj2_2 } from "@/views/openPlatform/code"
export default {
  name: "rightCode",
  data() {
    return {
      type: 'code',
      activeName: 'Java',
      requestData: "",
      responseData: "",
      descripData: "",
      javaCode: "",
      goCode: "",
      phpCode: "",
      cCode: "",
      nodeCode: "",
      allCode: {
        codeObj1_1,
        codeObj1_2,
        codeObj2_1,
        codeObj2_2
      }
    };
  },

  created() {
    this.$eventBus.$on('treeClick', (id) => {
      this.javaCode = this.allCode[("codeObj" + id)].java
      this.goCode = this.allCode[("codeObj" + id)].go
      this.phpCode = this.allCode[("codeObj" + id)].php
      this.cCode = this.allCode[("codeObj" + id)].c
      this.nodeCode = this.allCode[("codeObj" + id)].node
      this.$nextTick(() => {
        Prism.highlightAll();
      })
      this.type = "code"
    })
    this.$eventBus.$on('addRequestData', (data) => {
      this.requestData = data
      this.type = "params"
    })
    this.$eventBus.$on('addResponseData', (data) => {
      this.responseData = data
    })
    this.$eventBus.$on('addDescripData', (data) => {
      this.descripData = data
    })
  },
  mounted() {
    // 当组件挂载完成后，调用Prism.highlightAll()来高亮代码
    // Prism.highlightAll();
  },
  methods: {
    handleClick1(tab, event) {
      console.log(tab, event);
    },
    handleClick2(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>
<style scoped>
.rightCode /deep/.el-card__header {
  background: #e2ecfa;
}
</style>
<style lang="scss" scoped>
.rightCode {
  width: 58%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  border: 1px solid #ddd;

  .box-card {
    color: #456;

    .title {
      color: #333;
    }

    .jsonBox {
      min-height: calc(40vh - 150px);
    }
  }
}
</style>
