<template>
  <div class="app-container leftTree">
    <el-input placeholder="输入关键字进行过滤" v-model="filterText">
    </el-input>

    <el-tree class="filter-tree" :data="data" :props="defaultProps" @node-click="handlClick" node-key="id"
      :filter-node-method="filterNode" ref="tree" highlight-current>
    </el-tree>
  </div>
</template>

<script>

export default {
  name: "leftTree",
  data() {
    return {
      filterText: '',
      data: [
        {
          id: "1",
          label: '代收',
          disabled: true,
          children: [
            {
              id: "1_1",
              label: '创建代收/支付交易下单接口'
            },
            {
              id: "1_2",
              label: '查询代收/支付单接口'
            }]
        },
        {
          id: "2",
          label: '代付',
          disabled: true,
          children: [
            {
              id: "2_1",
              label: '创建代付/转账交易下单接口'
            },
            {
              id: "2_2",
              label: '查询代付/转账单接口'
            }
          ]

        }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },

  created() {
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handlClick(data, node) {
      if (node.childNodes.length === 0) {
        this.$eventBus.$emit('treeClick', node.data.id)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.leftTree {
  width: 20%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  border: 1px solid #ddd;

  .filter-tree {
    margin-top: 10px;
  }
}
</style>
