<template>
  <div class="order_page openPlatform">
    <leftTree></leftTree>
    <centerParams1_1 v-if="module == '1_1'"></centerParams1_1>
    <centerParams1_2 v-if="module == '1_2'"></centerParams1_2>
    <centerParams2_1 v-if="module == '2_1'"></centerParams2_1>
    <centerParams2_2 v-if="module == '2_2'"></centerParams2_2>
    <div class="emptyBox" v-if="module == ''">
      <span>请选择测试Api ^_^</span>
    </div>
    <rightCode></rightCode>
  </div>
</template>

<script>
import leftTree from "./component/leftTree.vue";
import centerParams1_1 from "./component/centerParams1_1.vue";
import centerParams1_2 from "./component/centerParams1_2.vue";
import centerParams2_1 from "./component/centerParams2_1.vue";
import centerParams2_2 from "./component/centerParams2_2.vue";
import rightCode from "./component/rightCode.vue";

export default {
  name: "openPlatform",
  components: {
    leftTree,
    centerParams1_1,
    centerParams1_2,
    centerParams2_1,
    centerParams2_2,
    rightCode
  },
  data() {
    return {
      module: "",
    };
  },
  created() {
    this.$eventBus.$on('treeClick', (id) => {
      this.module = id
    })
  },
  methods: {
  }
};
</script>

<style lang="scss">
.openPlatform {
  width: calc(100vw - 270px);
  display: flex;
  justify-content: space-between;

  &>div {
    padding: 10px;
  }

  .emptyBox {
    width: 20%;
    height: calc(100vh - 140px);
    border: 1px solid #ddd;
    text-align: center;
    color: #555;
    padding-top: 35px;
  }
}
</style>
